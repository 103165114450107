var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.getSessionDetailsLoading ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('GroupStatusCard', {
    attrs: {
      "dotType": "icon",
      "stats": _vm.stats
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('session-drawer', {
    attrs: {
      "from-overview": "",
      "session-detail": _vm.getSessionDetails
    }
  })], 1)], 1)], 1) : _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "height": "100",
      "type": "image"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "height": "727",
      "type": "image"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }