var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialLoading ? _c('div', [_c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1)], 1)], 1) : _vm.sessionActivitiesErrorMessage ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "second-text": _vm.sessionActivitiesErrorMessage,
      "first-text": "Ops!"
    }
  })], 1)], 1) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.activitiesLoading,
      "pagination": _vm.pagination,
      "title": "Session Activities"
    }
  }, [_c('v-data-table', {
    attrs: {
      "expanded": _vm.latestExpand,
      "headers": _vm.activityHeader,
      "items": _vm.sessionActivities,
      "loading": _vm.activitiesLoading,
      "disable-sort": "",
      "hide-default-footer": "",
      "single-expand": ""
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.latestExpand = $event;
      },
      "click:row": _vm.expendLatestRow
    },
    scopedSlots: _vm._u([{
      key: "item.action",
      fn: function fn(_ref) {
        var _vm$latestExpand$;
        var item = _ref.item;
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          },
          domProps: {
            "textContent": _vm._s(((_vm$latestExpand$ = _vm.latestExpand[0]) === null || _vm$latestExpand$ === void 0 ? void 0 : _vm$latestExpand$.id) === item.id ? 'mdi-chevron-up' : 'mdi-chevron-down')
          }
        })];
      }
    }, {
      key: "item.activity_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "cursor-pointer text-capitalize"
        }, [_vm._v(" " + _vm._s(item.type) + " ")])];
      }
    }, {
      key: "item.activity_name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "cursor-pointer"
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": _vm._f("unix")(item.timestamp)
          }
        })];
      }
    }, {
      key: "item.navigate",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "flex-row jc-c"
        }, [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleNavigation(item);
            }
          }
        }, [_c('i', {
          staticClass: "arrow-right"
        })])], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref6) {
        var _item$context, _item$context$sdk, _item$context2, _item$context2$sdk, _item$context3, _item$context4;
        var headers = _ref6.headers,
          item = _ref6.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-card', {
          staticClass: "pa-4",
          attrs: {
            "flat": "",
            "tile": ""
          }
        }, [_c('div', {
          staticClass: "d-flex justify-space-between align-top"
        }, [_c('pre', {
          staticClass: "pt-5 px-5 pb-5"
        }, [_vm._v(_vm._s(JSON.stringify(item.properties, null, 2)))]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "outlined": _vm.$vuetify.theme.dark
          },
          on: {
            "click": function click($event) {
              _vm.copyToClipboard(JSON.stringify(item.properties, null, 2));
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-content-copy")]), _c('span', [_vm._v("Copy")])], 1)], 1), _c('stack', {
          attrs: {
            "gap": "12px"
          }
        }, [_c('span', [_c('b', [_vm._v("Source:")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context = item.context) === null || _item$context === void 0 ? void 0 : (_item$context$sdk = _item$context.sdk) === null || _item$context$sdk === void 0 ? void 0 : _item$context$sdk.os) + " " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context2 = item.context) === null || _item$context2 === void 0 ? void 0 : (_item$context2$sdk = _item$context2.sdk) === null || _item$context2$sdk === void 0 ? void 0 : _item$context2$sdk.name) + " ")]), _c('span', [_c('b', [_vm._v("User agent:")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context3 = item.context) === null || _item$context3 === void 0 ? void 0 : _item$context3.user_agent) + " ")]), _c('span', [_c('b', [_vm._v("IP:")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context4 = item.context) === null || _item$context4 === void 0 ? void 0 : _item$context4.ip) + " ")])])], 1)], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }