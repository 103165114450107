var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-5"
  }, [_c('p', {
    staticClass: "value darkGrey--text",
    domProps: {
      "textContent": _vm._s(_vm.value)
    }
  }), _c('p', {
    staticClass: "labal darkGrey--text",
    domProps: {
      "textContent": _vm._s(_vm.label)
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }