<template>
  <div :style="{ gap }" class="v-stack">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Stack",
  props: {
    gap: {
      type: String,
      default: "8px",
    },
  },
};
</script>

<style lang="scss">
.v-stack {
  display: grid;
}
</style>
