<template>
  <div v-if="!getSessionDetailsLoading">
    <v-row>
      <v-col cols="12">
        <GroupStatusCard dotType="icon" :stats="stats" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <session-drawer
          from-overview
          :session-detail="getSessionDetails"
        ></session-drawer>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader height="100" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader height="727" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SessionActivity from "@/view/components/Sessions/SessionActivity.vue";
import GroupStatusCard from "@/view/components/Sessions/GroupStatusCard";
import SessionDrawer from "@/view/components/Sessions/SessionDetailPage/SessionDrawer.vue";
import SingleSessionStat from "@/view/components/Sessions/SingleSessionStat.vue";
import { mapGetters } from "vuex";
import { getDuration } from "@/core/services/helper.service";

export default defineComponent({
  statsText: {
    0: "Ended",
    1: "Active",
    2: "Expired",
  },
  statsColor: {
    0: "grey",
    1: "success",
    2: "error",
  },
  computed: {
    ...mapGetters({
      getSessionDetails: "getSessionDetails",
      getSessionDetailsLoading: "getSessionDetailsLoading",
    }),
    stats() {
      if (!this.getSessionDetails) {
        return [];
      }

      const { stats, end_at, start_at } = this.getSessionDetails;

      return [
        {
          title: "Errors",
          icon: "mdi-alert-circle",
          color: "error",
          count: stats.errors,
        },
        {
          title: "Feedback",
          icon: "mdi-message-alert",
          color: "primary",
          count: stats.feedback,
        },
        {
          title: "Activities",
          icon: "mdi-cursor-default-click",
          color: "primary",
          count: (stats?.screens ?? 0) + (stats?.events ?? 0),
          tooltip: `
              <ul>
                <li>Screens: ${stats?.screens || 0}</li>
                <li>Events: ${stats?.events || 0}</li>
              </ul>
            `,
        },
        {
          title: "Duration",
          icon: "mdi-clock",
          color: "primary",
          count: getDuration(start_at, end_at),
        },
        {
          title: "Date",
          icon: "mdi-calendar",
          color: "primary",
          from: "session",
          count: start_at,
          props: {
            timezone: this.getSessionDetails?.context?.device?.timezone,
          },
          time: true,
        },
        {
          title: "Status",
          icon: "mdi-circle",
          color: this.$options.statsColor[this.getSessionDetails.status],
          count: this.$options.statsText[this.getSessionDetails.status],
        },
      ];
    },
  },
  components: {
    SingleSessionStat,
    SessionDrawer,
    SessionActivity,
    GroupStatusCard,
  },
});
</script>

<style lang="scss" scoped>
.stats {
  border-radius: 6px !important;
  padding: 18px 48px;
}

.characteristics-card {
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }

  &__head {
    border-bottom: 1px solid #fafafa;
  }

  &__body {
    align-items: flex-start;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }

  &__head,
  &__body {
    padding: 14px 24px;
  }
}
</style>
