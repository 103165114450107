<template>
  <div v-if="initialLoading">
    <v-row style="margin-top: 24px">
      <v-col cols="6">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
  <v-row v-else-if="sessionActivitiesErrorMessage">
    <v-col cols="12">
      <no-data
        :second-text="sessionActivitiesErrorMessage"
        first-text="Ops!"
      ></no-data>
    </v-col>
  </v-row>
  <DatatableWrapper
    v-else
    :loading="activitiesLoading"
    :pagination="pagination"
    title="Session Activities"
  >
    <v-data-table
      :expanded.sync="latestExpand"
      :headers="activityHeader"
      :items="sessionActivities"
      :loading="activitiesLoading"
      disable-sort
      hide-default-footer
      single-expand
      @click:row="expendLatestRow"
    >
      <template #item.action="{ item }">
        <v-icon
          color="primary"
          v-text="
            latestExpand[0]?.id === item.id
              ? 'mdi-chevron-up'
              : 'mdi-chevron-down'
          "
        />
      </template>

      <template #item.activity_type="{ item }">
        <span class="cursor-pointer text-capitalize">
          {{ item.type }}
        </span>
      </template>

      <template #item.activity_name="{ item }">
        <div class="cursor-pointer">
          {{ item.name }}
        </div>
      </template>

      <template #item.created="{ item }">
        <vue-hoverable-date :date="item.timestamp | unix"></vue-hoverable-date>
      </template>

      <template #item.navigate="{ item }">
        <div class="flex-row jc-c">
          <v-btn icon @click="handleNavigation(item)">
            <i class="arrow-right"></i>
          </v-btn>
        </div>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card class="pa-4" flat tile>
            <div class="d-flex justify-space-between align-top">
              <pre class="pt-5 px-5 pb-5">{{
                JSON.stringify(item.properties, null, 2)
              }}</pre>
              <v-btn
                color="primary"
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                @click="
                  copyToClipboard(JSON.stringify(item.properties, null, 2))
                "
              >
                <v-icon>mdi-content-copy</v-icon>
                <span>Copy</span>
              </v-btn>
            </div>

            <stack gap="12px">
              <span>
                <b>Source:</b> {{ item?.context?.sdk?.os }}
                {{ item?.context?.sdk?.name }}
              </span>
              <span> <b>User agent:</b> {{ item?.context?.user_agent }} </span>
              <span> <b>IP:</b> {{ item?.context?.ip }} </span>
            </stack>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </DatatableWrapper>
</template>

<script>
import dayjs from "dayjs";
import DatatableWrapper from "@/view/components/App/DatatableWrapper.vue";
import Stack from "@/view/components/Common/Stack.vue";

export default {
  components: { Stack, DatatableWrapper },
  data() {
    return {
      dayjs,
      activityHeader: [
        {
          text: "",
          value: "action",
          width: 0,
        },
        {
          text: "Type",
          value: "activity_type",
          width: 120,
        },
        {
          text: "Name",
          value: "activity_name",
        },
        {
          text: "Created",
          value: "created",
          width: 140,
          cellClass: "no-wrap",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: 100,
          value: "navigate",
        },
      ],
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      latestExpand: [],
      sessionActivitiesErrorMessage: null,
      activitiesLoading: true,
      initialLoading: true,
      sessionActivities: [],
      totalActivitiesCount: 0,
    };
  },
  watch: {
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });

      this.pagination.page = newPage;
      this.getSessionActivities({ page: newPage, last_id: this.lastId });
    },
    totalActivitiesCount(value) {
      this.pagination.totalItems = value;
    },
  },
  computed: {
    lastId() {
      return (
        this.sessionActivities[this.sessionActivities.length - 1]?.id ?? ""
      );
    },
  },
  created() {
    this.getSessionActivities().then(() => {
      this.initialLoading = false;
    });
  },
  methods: {
    getSessionActivities({ page = this.pagination.page, last_id = "" } = {}) {
      this.sessionActivitiesErrorMessage = null;
      this.activitiesLoading = true;
      const params = {
        app_id: this.$route.params.app_id || "",
        session_id:
          this.$route.params.session_id || this.$route.query.session_id || "",
        feedback_id: this.$route.query.feedback_id || "",
        page,
        last_id,
      };

      return this.$coreApi
        .get(
          `${this.apiRoutes.core.session.allActivities}?${this.getQuery(
            params
          )}`
        )
        .then(({ data }) => {
          this.sessionActivities = data.data ?? [];
          this.totalActivitiesCount = data.meta.total;
        })
        .catch((err) => {
          this.sessionActivitiesErrorMessage = err.message;
        })
        .finally(() => {
          this.activitiesLoading = false;
        });
    },
    expendLatestRow(item) {
      if (this.latestExpand) {
        if (this.latestExpand[0] === item) {
          this.latestExpand = [];
        } else {
          this.latestExpand = [item];
        }
      } else {
        this.latestExpand = [item];
      }
    },
    handleNavigation(item) {
      this.$router.push({
        name: "session-details-activities",
        params: {
          app_id: item.app_id,
          session_id: item.session_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.rounded-10 {
  border-width: 1px;
  border-radius: 10px !important;
}

.px-5 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pt-5 {
  padding-top: 10px !important;
}

.pb-5 {
  padding-bottom: 10px !important;
}
</style>
