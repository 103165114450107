<template>
  <div class="pt-5">
    <p class="value darkGrey--text" v-text="value" />
    <p class="labal darkGrey--text" v-text="label" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
    },
  },
};
</script>

<style scoped>
.pt-5 {
  padding-top: 10px !important;
}

.value {
  font-size: 24px;
  font-weight: 600;
}

.labal {
  font-size: 14px;
  font-weight: 300;
  color: #809fb8;
  margin-top: 32px;
  text-transform: capitalize;
}
</style>
